import request from '@/utils/request'

// 预存款下单明细
export function getList(data) {
  return request({
    url: '/promoteAdmin/deposit-order/order-detail',
    method: 'post',
    data,
  })
}

// 活动下拉
export function activeSelect(data) {
  return request({
    url: '/promoteAdmin/deposit',
    method: 'post',
    data,
  })
}

/**
 * 预存款汇总统计
 */

// 预存款汇总统计
export function getSummaryList(data) {
  return request({
    url: '/promoteAdmin/deposit-order/total',
    method: 'post',
    data,
  })
}
